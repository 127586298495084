import React, { useEffect, useRef } from 'react';

import { cssMap } from '@atlaskit/css';
import { ButtonItem, LinkItem } from '@atlaskit/menu';
import { Box, Flex, Inline } from '@atlaskit/primitives/compiled';
import { token } from '@atlaskit/tokens';

const styles = cssMap({
	buttonContentStyles: {
		display: 'flex',
		alignItems: 'center',
		gap: token('space.150'),
		paddingInline: token('space.150'),
	},

	inlineItemStyles: {
		color: token('color.text.subtle'),
	},
	iconBoxStyles: {
		width: '32px',
		height: '32px',
		minWidth: '32px',
		borderStyle: 'solid',
		borderWidth: token('border.width'),
		borderColor: token('color.border'),
		borderRadius: token('border.radius'),
	},
});

const BaseActionItem = ({ title, icon }: { title: string; icon: React.ReactElement }) => {
	return (
		<Box xcss={styles.buttonContentStyles}>
			<Flex justifyContent="center" alignItems="center" xcss={styles.iconBoxStyles}>
				{icon}
			</Flex>
			<Inline xcss={styles.inlineItemStyles}>{title}</Inline>
		</Box>
	);
};

export function DynamicSurfaceActionItem({
	title,
	icon,
	isSelected,
	onClick,
	href,
}: {
	title: string;
	icon: React.ReactElement;
	isSelected: boolean;
	onClick?: () => void;
	href?: string;
}) {
	const ref = useRef<HTMLElement>(null);
	useEffect(() => {
		if (isSelected && ref.current) {
			ref.current.scrollIntoView({
				behavior: 'instant',
				block: 'nearest',
			});
		}
	}, [isSelected, ref]);

	if (href) {
		return (
			<LinkItem ref={ref} target="_blank" isSelected={isSelected} href={href}>
				<BaseActionItem title={title} icon={icon} />
			</LinkItem>
		);
	}
	return (
		<ButtonItem ref={ref} onClick={onClick} isSelected={isSelected}>
			<BaseActionItem title={title} icon={icon} />
		</ButtonItem>
	);
}
